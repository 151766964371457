import { Link } from "react-router-dom";
import Heading from "./Heading";
import ResigtrationSchedule from '../assets/RAAISA 2024 Program Schedule.pdf';


const CommingSoonBanner = () => {
  return (
    <div className='size_text w-full py-[5em] text-secondary text-center'>
      {/* <p className='text-[3em]'>Coming Soon....</p> */}
      <section className='no_bubble_layout'>
        <div className='mt-[2em]'>
          <Heading
            title={'RAAISA 2024 Program Schedule'}
            colorClass={
              'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'
            }
          />
          <div className='flex flex-col items-center pt-[2em] gap-[0.5em]'>
          <a
            href={ResigtrationSchedule}
            download
            className="py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn"
          >
            <p className="text-[0.8em] ">Check here to Download</p>
          </a>
            {/* <button
              className="py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn group disabled:bg-gray-300 disabled:pointer-events-none"
              disabled
            >
              <p className="text-[0.8em]">Will be Uploaded shortly...</p>
            </button> */}
            {/* <PrimaryLinkButton>Will be Uploaded shortly...</PrimaryLinkButton> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommingSoonBanner;
